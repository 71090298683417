 <template>
  <div class="video-component">
    <div class="title-line">
      <span
        >位置：<span class="title-label">{{ datas.position }}</span></span
      >
    </div>
    <div>
      <!-- <jessibucaTestor
        style="width: 100%"
        ref="jessibuca"
        :videoUrl="videoUrl"
        :error="videoError"
        :hasAudio="true"
        fluent
        autoplay
        live
        stretch
      /> -->
      <Player
        :video-url="videoUrl"
        height="470px"
        :autoplay="true"
        :has-audio="false"
        :isFullResize="false"
      />
      <!-- :index="new Date().getTime()" -->
    </div>
  </div>
</template>

<script>
import Player from "@/components/jessibuca";
export default {
  name: "videoComponent",
  components: {
    Player,
  },
  props: {
    datas: {
      require: true,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    videoUrl() {
      return `${this.datas.source}`;
    },
  },
  data() {
    return {};
  },
  methods: {
    videoError(error) {
      console.log("xxx", error);
    },
  },
  // mounted() {
  //   this.$refs["jessibuca"].on("timeout", function (error) {
  //     console.log("xxx-timeout:", error);
  //   });
  // },
  // destroyed() {
  //   this.$destroy("videojs");
  // },
};
</script>

<style lang="less" scoped>
.video-component {
  .title-line {
    margin-bottom: 10px;
    font-weight: bold;
    .title-label {
      font-weight: normal;
    }
  }
}
</style>