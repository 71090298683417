var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "video-component" }, [
    _c("div", { staticClass: "title-line" }, [
      _c("span", [
        _vm._v("位置："),
        _c("span", { staticClass: "title-label" }, [
          _vm._v(_vm._s(_vm.datas.position)),
        ]),
      ]),
    ]),
    _c(
      "div",
      [
        _c("Player", {
          attrs: {
            "video-url": _vm.videoUrl,
            height: "470px",
            autoplay: true,
            "has-audio": false,
            isFullResize: false,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }