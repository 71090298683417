export const FloorList = [
  { label: '办公区', name: 'office' },
  { label: '资料区', name: 'data' },
];
export const Sources = {
  office: [
    {
      position: '档案室办公区1',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000067@34020000001320000067.flv`,
    },
    {
      position: '档案室办公区2',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000068@34020000001320000068.flv`,
    },
  ],
  data: [
    {
      position: '档案室资料区1',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000069@34020000001320000069.flv`,
    },
    {
      position: '档案室资料区2',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000070@34020000001320000070.flv`,
    },
    {
      position: '档案室资料区3',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000071@34020000001320000071.flv`,
    },
  ],
};
